export const totalPhasePowerConsumption = (loadLogs)=>{
    console.log(loadLogs,"log");
    if(Object.keys(loadLogs).length===0){
        return '0 W'
    }
    let pp =0;
    for(let id of Object.keys(loadLogs)){
        let d=loadLogs[id]
        for(let ph of Object.keys(d)){
            let load = d[ph].data
            pp+=load[load.length-1]?.p || 0
        }
    }
    return `${pp.toFixed(2)} W`
}